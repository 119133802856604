import Address from './Address' 
import Subscription from './Subscription' 

export default class Company {

  constructor() {
    this.legal_name = ''
    this.trading_name = ''
    this.registration_number = ''
    this.vat_number = ''
    this.user_id = ''
    this.logo = ''
    this.logo_url = ''
    this.address = new Address
    this.subscription = new Subscription
    this.industry_id = ''
    this.white_logo_background = false
    this.namingConventions = null
    this.asset_register = {}
  }

}